import { fetcher } from "@/api/index"
import { postAppData, SPACES_APP } from "../apps"
import { PREFERRED_SUPPLIERS_APP } from "./../apps/index"

export const getSuppliersByTask = async (task: string) => {
  return await fetcher(`/tasks/${task}/suppliers`)
}

export const assignSuppliers = ({ task, suppliers }) => {
  return fetcher(`/tasks/${task}/suppliers`, {
    method: "POST",
    body: JSON.stringify({
      suppliers,
    }),
  })
}

export const createVendor = ({ reference, emails = [] }) => {
  return fetcher(`/suppliers`, {
    method: "POST",
    body: JSON.stringify({
      reference,
      emails,
    }),
  })
}

export const createSupplierSpace = async ({
  name,
  reference,
}: {
  name: string
  reference: string
}) => {
  const createdSpace = await postAppData(SPACES_APP, {
    name,
    reference,
    categories: ["Supplier", "Organisation"],
  })

  const addPrefferedSuppliers = await postAppData(PREFERRED_SUPPLIERS_APP, {
    spaces: [createdSpace.uid],
  })

  return addPrefferedSuppliers
}
