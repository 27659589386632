import { selectedSpaceAtom } from "@/atoms/spaces"
import { Button } from "@/shared/components/ui/button"
import { Calendar } from "@/shared/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover"
import { cn, getDateFnsLocale } from "@/utils/helpers"
import { faCalendar } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as locales from "date-fns/locale/index.js"
import dayjs from "dayjs"
import { useState } from "react"
import { useRecoilValue } from "recoil"

type DatePickerProps = React.ComponentProps<typeof Calendar> & {
  date: Date | null
  placeholder?: string
  onChange: (date: Date) => void
}

export function DatePicker({
  date,
  placeholder,
  onChange,
  ...props
}: DatePickerProps) {
  const [open, setOpen] = useState<boolean>(false)
  const space = useRecoilValue(selectedSpaceAtom)
  const spaceLocale = getDateFnsLocale(space?.locale)
  const locale = locales[spaceLocale] ?? locales["enUS"]

  const handleChange = (date: Date) => {
    onChange(date)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-gray-500",
          )}
        >
          <FontAwesomeIcon icon={faCalendar} className="mr-2 h-4 w-4" />
          {date ? (
            dayjs(date).format("LL")
          ) : (
            <span>{!!placeholder ? placeholder : "Select a date"}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto bg-white p-0">
        <Calendar
          {...props}
          locale={locale}
          mode="single"
          weekStartsOn={1}
          selected={date}
          onSelect={handleChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
