import { fetcher } from "../index"

export const updateNodeContent = async (node, content = {}) => {
  return await fetcher(`/nodes/${node}`, {
    method: "PUT",
    body: JSON.stringify({ ...content }),
  })
}

export const deleteNode = async (node, soft) => {
  return await fetcher(`/nodes/${node}`, {
    method: "DELETE",
    body: JSON.stringify({ soft }),
  })
}
